/**
 *  If a keyup event is fired by the company-search field
 *  the text is searched for the given string. If not
 *  found in an object, it gets the class "search-filtered"
 */
$("#company-search").on("keyup", function () {
    function sanitizeString(str) {
        str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "");
        return str.trim();
    }
    var searchfieldvalue = sanitizeString($(this).val());

    var target = ".company";
    var companies = $(target);
    companies.removeClass("search-filtered");

    /**
     *  Start running if the value of the searchfield
     *  is larger or equal to 3.
     *  Searched areas are: Address, Title, ShortDescription and Tags
     */
    if (searchfieldvalue.length >= 3) {
        $.each(companies, function (key, value) {
            var companyTitle = $(this).find(".company-title strong").html();
            var companyTags = $(this).find(".company-tags p").html();
            var companyAdress = $(this).find(".company-address p").html();
            var companyDesc = $(this).find(".short-description p").html();

            var lowerCompanyTitle = companyTitle.toLowerCase();
            var lowerCompanyTags =
                typeof companyTags === "undefined"
                    ? ""
                    : companyTags.toLowerCase();
            var lowerCompanyAdress =
                typeof companyAdress === "undefined"
                    ? ""
                    : companyAdress.toLowerCase();
            var lowerCompanyDesc =
                typeof companyDesc === "undefined"
                    ? ""
                    : companyDesc.toLowerCase();

            if (
                lowerCompanyTitle.indexOf(searchfieldvalue.toLowerCase()) <=
                    -1 &&
                lowerCompanyTags
                    .toLowerCase()
                    .indexOf(searchfieldvalue.toLowerCase()) <= -1 &&
                lowerCompanyAdress
                    .toLowerCase()
                    .indexOf(searchfieldvalue.toLowerCase()) <= -1 &&
                lowerCompanyDesc
                    .toLowerCase()
                    .indexOf(searchfieldvalue.toLowerCase()) <= -1
            ) {
                $(this).addClass("search-filtered");
            }
        });

        $(".search-alert").remove();
        $(".companies").before(
            '<p class="search-alert alert alert-info mt-3"><strong>Suchergebnisse für: </strong><span class="fst-italic">&laquo;' +
                searchfieldvalue +
                "&raquo;</span></p>"
        );
    } else {
        $(".search-alert").remove();
    }
});

/**
 *  If the business select field is changed all companies are
 *  looped and if the currently selected business id is not in the
 *  data-business attribute of the company element it gets the class
 *  "business-filtered"
 */
$("#company-business-filter").on("change", function () {
    var selectedBusinessID = $(this).find("option:selected").val();
    var target = ".company";
    var companies = $(target);

    companies.removeClass("business-filtered");

    /**
     *  If the none-filter is selected the function has to
     *  cancel its action.
     */
    if (selectedBusinessID == "none") {
        return true;
    }

    /**
     *  Run through each company object and check if the current
     *  selected business id is part of the data-attribute.
     */
    $.each(companies, function () {
        var currentCompany = $(this);
        var givenBusinessIDs = currentCompany.data("businesses");

        if (
            givenBusinessIDs.length <= 0 ||
            givenBusinessIDs.indexOf(parseInt(selectedBusinessID)) < 0
        ) {
            currentCompany.addClass("business-filtered");
        }
    });
});

/**
 *  If one of the button group for filtering by characters or
 *  numbers is clicked hide all other companies except the
 *  one that fits to the selection.
 */
$("#alphanum-filter button").on("click", function (e) {
    /**
     *  Select the currently clicked filter button
     *  and set it to active
     */
    e.preventDefault();

    var allButtons = $("#alphanum-filter button");
    allButtons.removeClass("btn-secondary").addClass("btn-link");
    $(this).removeClass("btn-link").addClass("btn-secondary");

    var alphanumValue = $(this).val();
    var target = ".company";
    var companies = $(target);
    companies.removeClass("alphanum-filtered");

    /**
     *  If the all-filter is selected the function has to
     *  cancel its action.
     */
    if (alphanumValue == "all") {
        return true;
    }

    /**
     *  Run through each company and check if the selected
     *  charakter is the first letter of the company title.
     *  If the selected button is the #-button, check the
     *  first character for being a number.
     */
    $.each(companies, function (key, value) {
        var companyTitle = $(this).find(".company-title strong").html();
        var lowerCompanyTitle = companyTitle.toLowerCase();

        if (alphanumValue == "#") {
            if (isNaN(parseInt(lowerCompanyTitle[0]))) {
                $(this).addClass("alphanum-filtered");
            }
        } else {
            if (lowerCompanyTitle[0] != alphanumValue) {
                $(this).addClass("alphanum-filtered");
            }
        }
    });

    return false;
});

/**
 *  This function returns a specific URL parameter
 *
 *  @param sParam The wanted parameter
 *  @return string|undefined
 */
function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}

$(document).ready(function () {
    /**
     *  Check if there is a search value in the parameter
     *  and prefill the search for the user
     */
    if (getUrlParameter("s")) {
        $("#company-search").val(getUrlParameter("s"));
        $("#company-search").keyup();
    }

    /**
     *  Check if there is a id value in the parameter
     *  and open that id and scroll down to it
     */
    if (getUrlParameter("cid")) {
        var cid = getUrlParameter("cid");
        var collapse = $("#company-collapse-" + cid);
        if (collapse) {
            new Collapse(collapse, {});
            var $target = $("#company-" + cid);
            $target =
                ($target.length && $target) ||
                $("[name=" + this.hash.slice(1) + "]");
            if ($target.length) {
                var targetOffset = $target.offset().top;
                $("html,body").animate({ scrollTop: targetOffset }, 1000);
                return false;
            }
        }
    }

    /**
     *  Check if there is a id value in the parameter
     *  and open that id and scroll down to it
     */
    if (getUrlParameter("aiid")) {
        var aiid = getUrlParameter("aiid");
        var collapse = $("#item-accordion-collapse-" + aiid);
        if (collapse) {
            new Collapse(collapse, {});
            var $target = $("#item-heading-" + aiid);
            $target =
                ($target.length && $target) ||
                $("[name=" + this.hash.slice(1) + "]");
            if ($target.length) {
                var targetOffset = $target.offset().top;
                $("html,body").animate({ scrollTop: targetOffset }, 1000);
                return false;
            }
        }
    }
});
