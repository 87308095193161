/**
 * ##############################
 * Drittanbieter Bibliotheken
 * ##############################
 */

/* JS-Cookies */
const Cookies = require("js-cookie");
window.Cookies = Cookies;

/* Bootstrap */
const Alert = require("bootstrap/js/dist/alert");
window.Alert = Alert;

const Button = require("bootstrap/js/dist/button");
window.Button = Button;

const Collapse = require("bootstrap/js/dist/collapse");
window.Collapse = Collapse;

const Dropdown = require("bootstrap/js/dist/dropdown");
window.Dropdown = Dropdown;

const OffCanvas = require("bootstrap/js/dist/offcanvas");
window.OffCanvas = OffCanvas;

const Popover = require("bootstrap/js/dist/popover");
window.Popover = Popover;

const Modal = require("bootstrap/js/dist/modal");
window.Modal = Modal;

// Deaktivierte Scripte aus Bootstrap

// const Carousel = require('bootstrap/js/dist/carousel');
// window.Carousel = Carousel;

// const Scrollspy = require('bootstrap/js/dist/scrollspy');
// window.Scrollspy = Scrollspy;

// const Tab = require('bootstrap/js/dist/tab');
// window.Tab = Tab;

// const Toast = require('bootstrap/js/dist/toast');
// window.Toast = Toast;

// const Tooltip = require('bootstrap/js/dist/tooltip');
// window.Tooltip = Tooltip;

const Fancybox = require("@fancyapps/ui");
window.Fancybox = Fancybox;

/* Font Awesome */
require("@fortawesome/fontawesome-free/js/fontawesome");
require("@fortawesome/fontawesome-free/js/solid");
require("@fortawesome/fontawesome-free/js/brands");
require("@fortawesome/fontawesome-free/js/regular");

require("jquery");
require("slick-carousel");

/**
 * ##############################
 * Eigene Scripte
 * ##############################
 */
require("./helpers.js");
require("./cookie-consent.js");
require("./event.js");
require("./forms.js");
require("./homepage-slider.js");
require("./members-page.js");
require("./modals.js");
require("./popover.js");
require("./privacy.js");
require("./recaptcha.js");
require("./securemailto.js");
require("./simditor.js");
require("./simditor-de.js");

/**
 * ##############################
 * Styles
 * ##############################
 */
import "./../sass/app.sass";
