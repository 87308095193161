document.addEventListener("DOMContentLoaded", function() {
    let mails = document.querySelectorAll('a[data-receiver]');
    mails.forEach(function(mail){
        mail.addEventListener('click', unlockMailto);
    });
});

function unlockMailto(event){
    event.preventDefault();
    let address = "mailto:"+event.target.closest('a').getAttribute('data-receiver')+"@"+event.target.closest('a').getAttribute('data-domain');
    location.href = address;
}
