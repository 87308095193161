/**
 * Diese Funktion verwandelt den Buchstaben an einem
 * gewünschten Index in einen Großbuchstaben.
 */
window.capitalizeLetterAt = function(string, index) {
    return string.charAt(index).toUpperCase() + string.slice(1);
}

/**
 * Einige Referenzen braucht man fast immer.
 * Also stelle diese global zur Verfügung.
 */
window.body = document.body;
window.navbar = document.getElementById('navbar');

function currentYPosition() {
    if (self.pageYOffset) return self.pageYOffset;
    if (document.documentElement && document.documentElement.scrollTop)
        return document.documentElement.scrollTop;
    if (document.body.scrollTop) return document.body.scrollTop;
    return 0;
}

function elmYPosition(eID, offset = 0) {
    var elm = document.getElementById(eID);
    var y = elm.offsetTop - offset;
    var node = elm;
    while (node.offsetParent && node.offsetParent != document.body) {
        node = node.offsetParent;
        y += node.offsetTop;
    } return y;
}

window.smoothScroll = function(eID, offset = 0) {
    var startY = currentYPosition();
    var stopY = elmYPosition(eID, offset);
    var distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
        scrollTo(0, stopY); return;
    }
    var speed = Math.round(distance / 100);
    if (speed >= 20) speed = 20;
    var step = Math.round(distance / 25);
    var leapY = stopY > startY ? startY + step : startY - step;
    var timer = 0;
    if (stopY > startY) {
        for ( var i=startY; i<stopY; i+=step ) {
            setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
            leapY += step; if (leapY > stopY) leapY = stopY; timer++;
        } return;
    }
    for ( var i=startY; i>stopY; i-=step ) {
        setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
        leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
    }
}

let touchHovers = document.querySelectorAll('.touch-hover');

touchHovers.forEach(function(item){
    item.addEventListener('touchstart', event => {
        event.currentTarget.classList.add('touch');
    });

    item.addEventListener('touchend', event => {
        event.currentTarget.classList.remove('touch');
    });
});
