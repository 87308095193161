var homepageSlider = document.querySelector('.homepage-slider');

function doAnimations(elements) {
    var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    elements.each(function () {
        var $this = $(this);
        var $animationDelay = $this.data('delay');
        var $animationType = 'animate__animated ' + 'animate__' + $this.data('animation');
        $this.css({
            'animation-delay': $animationDelay,
            '-webkit-animation-delay': $animationDelay
        });
        $this.addClass($animationType).one(animationEndEvents, function () {
            $this.removeClass($animationType);
        });
    });
}

if(homepageSlider != null){
    $('.homepage-slider').on('init', function (e, slick) {
        var $firstAnimatingElements = $(this).find('.slide-content');
        doAnimations($firstAnimatingElements);
    });

    /**
     *  Before the next slide is shown get the first animated elements
     *  in the slider and do the animation magic with it
     */
    $('.homepage-slider').on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        var $animatingElements = $(this).find('.slide-content');
        doAnimations($animatingElements);
    });

    $('.homepage-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        prevArrow: $('.header-arrow.prev'),
        nextArrow: $('.header-arrow.next')
    });
}
