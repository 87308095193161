require('simditor')
require('simditor-i18n')
/**
 *  Here the WYSIWYG Editor simditor is initialized for
 *  the textarea in the company edit form
 */
 var simditorTextarea = $('#Form_CompanyProfileForm_CompanyShortDescription');
 if (simditorTextarea.length > 0) {
     Simditor.locale = 'de-DE';
     var editor = new Simditor({
         textarea: simditorTextarea,
         toolbar: ['title', 'bold', 'italic', 'underline', 'strikethrough', 'color', 'ol', 'ul', 'table', 'link', 'hr', 'alignment']
     });

     editor.on('valuechanged', function (event) {
         var newContent = $('.simditor-body').html();
         $('#Form_CompanyProfileForm_CompanyShortDescription').html(newContent);
     });
 }
