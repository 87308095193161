let recaptchaPublicKey = body.getAttribute('data-grcpublic');
let tokenFieldsNewsletter = document.querySelectorAll('.google-token-field');

document.addEventListener("DOMContentLoaded", function() {
	if(recaptchaPublicKey != null ){
	    grecaptcha.ready(function() {
	        tokenFieldsNewsletter.forEach(function(field){
                grecaptcha.execute(recaptchaPublicKey, {action: 'submit'}).then(function(token) {
                    field.value = token;
                });
            });
	    });
	}
});