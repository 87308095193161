Simditor.i18n['de-DE'] = {
    'blockquote': 'Blockzitat',
    'bold': 'Fett',
    'code': 'Quelltext',
    'color': 'Farbe',
    'coloredText': 'Gefärbter Text',
    'hr': 'Horizontale Linie',
    'image': 'Bild',
    'externalImage': 'Externes Bild',
    'uploadImage': 'Bild hochladen',
    'uploadFailed': 'Hochladen fehlgeschlagen',
    'uploadError': 'Fehler beim Hochladen',
    'imageUrl': 'Url',
    'imageSize': 'Bildgröße',
    'imageAlt': 'Alt-Text',
    'restoreImageSize': 'Bildgröße wiederherstellen',
    'uploading': 'Lade hoch',
    'indent': 'Einzug erhöhen',
    'outdent': 'Einzug verringern',
    'italic': 'Kursiv',
    'link': 'Link',
    'linkText': 'Linktext',
    'linkUrl': 'Url',
    'linkTarget': 'Ziel',
    'openLinkInCurrentWindow': 'Im gleichen Fenster öffnen',
    'openLinkInNewWindow': 'Im neuen Fenster öffnen',
    'removeLink': 'Link entfernen',
    'ol': 'Geordnete Liste',
    'ul': 'Ungeordnete Liste',
    'strikethrough': 'Durchgestrichen',
    'table': 'Tabelle',
    'deleteRow': 'Zeile löschen',
    'insertRowAbove': 'Zeile oberhalb',
    'insertRowBelow': 'Zeile unterhalb',
    'deleteColumn': 'Spalte löschen',
    'insertColumnLeft': 'Spalte links',
    'insertColumnRight': 'Spalte rechts',
    'deleteTable': 'Tabelle entfernen',
    'title': 'Titel',
    'normalText': 'Absatz',
    'underline': 'Unterstrichen',
    'alignment': 'Ausrichtung',
    'alignCenter': 'Mittig',
    'alignLeft': 'Links',
    'alignRight': 'Rechts',
    'alignJustify': 'Blocksatz',
    'selectLanguage': 'Sprache auswählen',
    'fontScale': 'Schriftgröße',
    'fontScaleXLarge': 'Sehr groß',
    'fontScaleLarge': 'Groß',
    'fontScaleNormal': 'Normal',
    'fontScaleSmall': 'Klein',
    'fontScaleXSmall': 'Sehr klein'
};