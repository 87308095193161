let cookieApprovalModal = document.getElementById('cookie-consent');
let noCookiesModal = document.getElementById('no-cookies');

let googleTagManagerID = body.getAttribute('data-gtmcode');
let crawlerAgentRegex = /bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex/i;
let videoplattforms = ['youtube', 'vimeo', 'dailymotion'];
let mapplattforms = ['map', 'maps', 'osm'];

window.dataLayer = window.dataLayer || [];

let consentState;
let consentGroups;

let cookieDomain = window.location.host.split('.').reverse();
cookieDomain = cookieDomain[1] + '.' + cookieDomain[0];
cookieDomain = cookieDomain.includes('localhost') > 0 ? 'localhost': cookieDomain;


/**
 * ######################################
 * Event-Handler for "Document ready"
 * ######################################
 */

 document.addEventListener("DOMContentLoaded", function() {

    consentState = Cookies.get('cookie_consent');
    consentGroups = Cookies.get('cookie_groups');

    if (navigator.cookieEnabled) {
        /** Cookies are allowed in this browser */

        if (consentState == 'set' && typeof consentGroups != 'undefinded' && consentGroups.includes('comfort-cookies')) {
            Cookies.set('remember_videos', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
            Cookies.set('remember_maps', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
            Cookies.set('remember_misc', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
        }

        let iframeWrappers = document.querySelectorAll('div.ratio.external-content');

        iframeWrappers.forEach(function (iframeWrapper, index) {
            let iframe = iframeWrapper.querySelector('iframe');

            if(typeof(iframe.getAttribute('data-src')) != 'undefined'){

                let isLight = iframeWrapper.classList == 'light';
                let dataSrc = iframe.getAttribute('data-src');

                if (videoplattforms.some(function(v) { return dataSrc.indexOf(v) >= 0; })) {
                    if (Cookies.get('remember_videos') != 'true') {
                        generateOverlay(iframe, 'video', index, isLight);
                    } else {
                        iframe.setAttribute('src', iframe.getAttribute('data-src'));
                    }
                }else if(mapplattforms.some(function(v) { return dataSrc.indexOf(v) >= 0; })){
                    if (Cookies.get('remember_maps') != 'true') {
                        generateOverlay(iframe, 'map', index, isLight);
                    } else {
                        iframe.setAttribute('src', iframe.getAttribute('data-src'));
                    }
                }else{
                    if (Cookies.get('remember_misc') != 'true') {
                        generateOverlay(iframe, 'misc', index, isLight);
                    } else {
                        iframe.setAttribute('src', iframe.getAttribute('data-src'));
                    }
                }

            }
        });

        if (typeof consentState == 'undefined' || consentState == 'revoked') {
            /** Consent state not defined (no cookie) OR revoked */

            // Delete Cookies
            let allCookies = Cookies.get();
            for (let key in allCookies) {
                Cookies.remove(key, {path: '/', domain: cookieDomain});

                if (key.includes('_gid') || key.includes('_gat') || key.includes('_ga') || key.includes('_fbp')) {
                    Cookies.remove(key, {path: '/', domain: cookieDomain});
                    Cookies.remove(key, {path: '/', domain: '.'+cookieDomain});
                    Cookies.remove(key, {path: '/', domain: 'dev.'+cookieDomain});
                    Cookies.remove(key, {path: '/', domain: '.dev.'+cookieDomain});
                }
            }

            if (body.classList.contains('PrivacyPage') == false && body.classList.contains('ImprintPage') == false) {
                /** Open cookie consent on the privacy page only if it is revoked */
                if (!crawlerAgentRegex.test(navigator.userAgent)) {
                    new Modal(cookieApprovalModal, {
                        'backdrop': 'static',
                        'keyboard': false
                    }).show();
                }
            }

        } else if (consentState == 'set') {
            /** Consent state is defined (cookie exists) and it is defined as set */

            if (typeof consentGroups != 'undefined') {
                /** Consent groups are defined (cookie exists) */

                if (consentGroups.includes('tracking-cookies') || consentGroups.includes('targeting-cookies')) {
                    /** Consent groups include "Tracking Cookies" or "Targeting-Cookies" */

                    if (typeof googleTagManagerID != 'undefined') {
                        /** TagManager-ID is set in the backend */

                        (function (w, d, s, l, i) {
                            w[l] = w[l] || [];
                            w[l].push({
                                'gtm.start':
                                    new Date().getTime(), event: 'gtm.js'
                            });
                            let f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                            j.async = true;
                            j.src =
                                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                            f.parentNode.insertBefore(j, f);
                        })(window, document, 'script', 'dataLayer', "" + googleTagManagerID + "");

                        let noScriptTagGoogle = document.createElement('noscript');
                        let iframeGooglePixel = document.createElement('iframe');
                        iframeGooglePixel.src = "https://www.googletagmanager.com/ns.html?id=" + googleTagManagerID + "";
                        iframeGooglePixel.alt = "Google Tag Manager";
                        iframeGooglePixel.height = "0";
                        iframeGooglePixel.width = "0";
                        iframeGooglePixel.style = "display:none;visibility:hidden";
                        noScriptTagGoogle.appendChild(iframeGooglePixel);
                        document.getElementsByTagName('body')[0].appendChild(noScriptTagGoogle);
                    }
                }

                if (consentGroups.indexOf('targeting-cookies') > 1) {
                    Cookies.set('enable_marketing', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
                }

            }
        }
    } else {
        /** Cookies are disabled in this browser */

        document.getElementById('cookie-consent-revoke').remove();
        if (!crawlerAgentRegex.test(navigator.userAgent)) {
            new Modal(noCookiesModal, {
                'backdrop': 'static',
                'keyboard': false
            }).show();
        }
    }

});

/**
 * ######################################
 * Event-Handler for "Allow all"
 * ######################################
 */
document.getElementById('cookie-consent-allow').addEventListener('click', event => {
    event.preventDefault();
    Cookies.set('cookie_consent', 'set', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.set('cookie_groups', 'essential-cookies.comfort-cookies.tracking-cookies.targeting-cookies', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    Modal.getInstance(cookieApprovalModal).hide();
    setTimeout(function () {
        location.reload();
    }, 1000);
});

/**
 * ######################################
 * Event-Handler for "Deny all"
 * ######################################
 */
 document.getElementById('cookie-consent-deny').addEventListener('click', event => {
    event.preventDefault();
    document.getElementById('cookie-consent-groups').click();
 });

/**
 * ######################################
 * Event-Handler for "Apply selection"
 * ######################################
 */
document.getElementById('cookie-consent-groups').addEventListener('click', event => {
    event.preventDefault();
    Cookies.set('cookie_consent', 'set', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    let cookieGroupsWrapper = event.target.closest('.collapse');
    let switches = cookieGroupsWrapper.querySelectorAll('.form-check input');
    let cookieGroups = 'essential-cookies';

    switches.forEach(function (switcher) {
        if (switcher.checked) {
            cookieGroups = cookieGroups + '.' + switcher.id;
        }
    });

    Cookies.set('cookie_groups', cookieGroups, {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    Modal.getInstance(cookieApprovalModal).hide();
    setTimeout(function () {
        location.reload();
    }, 250);
});

/**
 * ######################################
 * Event-Handler for "Revoke button"
 * ######################################
 */
 document.getElementById('cookie-consent-revoke').addEventListener('click', event => {
    event.preventDefault();
    Cookies.set('cookie_consent', 'revoked', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.set('cookie_groups', 'essential-cookies', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    Cookies.remove('enable_marketing', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.remove('remember_maps', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.remove('remember_videos', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.remove('remember_misc', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    Cookies.remove('banners_seen', {expires: 14, secure: true, sameSite: 'strict', path: '/'});
    Cookies.remove('prevURL', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    location.reload();

});

/**
 * ######################################
 * Event-Handler for "Unlock iFrame"
 * ######################################
 */
function unlockIFrame(event){
    let overlay = event.target.closest('.embed-overlay');
    let checkbox = overlay.querySelector('.remember-embed-decision');
    let iframe = overlay.parentElement.querySelector('iframe');

    if (checkbox.checked && consentGroups.includes('essential-cookies')) {
        if (overlay.classList.contains('embed-video')) {
            Cookies.set('remember_videos', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
        } else if (overlay.classList.contains('embed-map')) {
            Cookies.set('remember_maps', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
        } else if (overlay.classList.contains('embed-misc')) {
            Cookies.set('remember_misc', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
        }
        location.reload();
    }

    iframe.setAttribute('src', iframe.getAttribute('data-src'));
    overlay.remove();
}


/**
 * ######################################
 * Create and append overlay for an iframe
 * by content type (diff. bg-image and button)
 * ######################################
 */
let generateOverlay = (iframe, type = 'misc', index = 0, light = false) => {

    let types = [
        { type: 'video', label: 'Video' },
        { type: 'map', label: 'Karte' },
        { type: 'misc', label: 'Inhalt' },
    ];

    let typeLabel = types.find(el => el.type === type)['label'];

    let iframeWrapper = iframe.parentElement;
    iframeWrapper.classList.add('position-relative');

    let overlay = document.createElement('div');
    overlay.className = 'position-left position-top position-absolute d-flex bg-primary w-100 h-100 align-items-center justify-content-center text-center text-shadow embed-overlay embed-' + type + '';

    let innerWrapper = document.createElement('div');
    innerWrapper.className = 'text-white text-center text-shadow';

    let headline = document.createElement('p');
    headline.className = 'fs-6 fw-bold mb-0';
    headline.innerHTML = 'Weitere unterhaltsame Inhalte für Sie!';

    let description = document.createElement('p');
    description.className = 'mb-2';
    description.innerHTML = 'Jetzt '+typeLabel+' freischalten und multimedial genießen';

    let unlockWrapper = document.createElement('p');
    unlockWrapper.className = 'mb-0';

    if (typeof consentGroups != 'undefined') {
        if (consentGroups.includes('essential-cookies')) {
            let rememberDecisionWrapper = document.createElement('div');
            rememberDecisionWrapper.className = 'custom-control custom-switch mb-2';

            let rememberDecisionInput = document.createElement('input');
            rememberDecisionInput.type = 'checkbox';
            rememberDecisionInput.className = 'remember-embed-decision custom-control-input';
            rememberDecisionInput.id = 'targeting-cookies-'+index+'';
            rememberDecisionWrapper.append(rememberDecisionInput);

            let rememberDecisionLabel = document.createElement('label');
            rememberDecisionLabel.for = 'targeting-cookies-'+index+'';
            rememberDecisionLabel.className = 'custom-control-label';
            rememberDecisionLabel.innerHTML = 'Entscheidung merken!';
            rememberDecisionWrapper.append(rememberDecisionLabel);

            unlockWrapper.append(rememberDecisionWrapper);
        }
    }

    let unlockButton = document.createElement('button');
    unlockButton.className = 'unlock-iframe btn btn-outline-light btn-sm';
    unlockButton.innerHTML = 'Freischalten';
    unlockWrapper.append(unlockButton);
    unlockButton.addEventListener('click', unlockIFrame);

    innerWrapper.append(headline);
    innerWrapper.append(description);
    innerWrapper.append(unlockWrapper);
    overlay.append(innerWrapper);
    iframeWrapper.append(overlay);
}


