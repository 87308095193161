document.addEventListener("DOMContentLoaded", function() {

    let popovers = document.querySelectorAll('.pop-over');
    popovers.forEach(function(popover){
        new Popover(popover, {
            container: 'body'
        });
    });

});
