/**
 *  This function creates a random string with a
 *  given length
 *
 *  @param length The length of the random string
 */
function randomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

/**
 * This function creates a google map for an event.
 * It is in the global scope (window) so it can be fired
 * if the document is loaded or if the callback of
 * the Google Map API requirement is injected.
 */
window.eventMap = function () {
    var currentEvent = $(".event.single");
    var latitude = currentEvent.data("latitude");
    var longitude = currentEvent.data("longitude");

    var mapCenter = new google.maps.LatLng(latitude, longitude);
    var marker = new google.maps.Marker({ position: mapCenter });
    var mapProp = {
        center: mapCenter,
        zoom: 17,
    };
    var mapContainer = document.getElementById("event-map");
    if (mapContainer != null) {
        var map = new google.maps.Map(mapContainer, mapProp);
        marker.setMap(map);
    }
};

/**
 * This function clones the hidden companion template in
 * the modal window, adds and removes some classes that
 * identifies the clone as a new unique companion DOM-object
 * and binds all needed events to the new buttons, fields and
 * other elements. The new companion object is appended
 * to the companions list and at least some effects and
 * presets are done.
 *
 * @param e Event of the triggering element
 */
function cloneCompanion(e) {
    e.preventDefault();

    /**
     *  Generate a random token
     */
    var token = randomString(16);

    /**
     *  Clone the hidden companion template
     */
    var original = $(this).parent().find(".companion.template").first();
    var companionClone = original.clone();

    /**
     *  Re-Ident the companion with the random token
     *  and bind the event handlers to the new companion.
     */
    reidentCompanion(companionClone, token);
    bindCompanionEvents(companionClone);

    /**
     *  Add the clone to the companions list and change some classes.
     *  After faded in, pre-open the new collapsible container.
     */
    original.parent().append(companionClone);
    companionClone.addClass("new-companion");
    companionClone.removeClass("template");
    companionClone.fadeIn("slow");

    return false;
}

/**
 * This function takes a companion object and an ident-token.
 * The token is concatenated to some important spots in
 * the companion object.
 *
 * @param companion
 * @param token
 */
function reidentCompanion(companion, token) {
    /**
     * Get all important element inside the companion
     */
    var companionCollapseButton = companion.find("a.edit-companion-button");
    var companionCollapse = companion.find(".collapse");
    var companionForm = companion.find("form.save-companion-form");
    var companionFormGroups = companion.find(".form-group"); //inputs, selects and its labels
    var companionIDHiddenField = companion.find('input[name="CompanionID"]');

    /**
     * Set the token to the found elements
     */
    companion.attr("id", "companion-" + token);
    companionCollapseButton.attr(
        "data-bs-target",
        "#" + "companion-collapse-" + token
    );
    companionCollapseButton.attr(
        "aria-controls",
        "companion-collapse-" + token
    );
    companionCollapse.attr("id", "companion-collapse-" + token);
    companionForm.attr("id", "save-companion-form-" + token);
    companionForm.attr("name", "SaveCompanionForm-" + token);
    companionIDHiddenField.attr("id", "companion-id-" + token);
    companionIDHiddenField.attr("name", "CompanionID");
    companionIDHiddenField.val(token);

    /**
     * Set the token to the input-fields and its labels
     * inside the companion
     */
    $.each(companionFormGroups, function (key, value) {
        var inputField = $(this).find("select,input,textarea");
        var inputLabel = $(this).find("label");
        var oldID = inputField.attr("id");
        var newID = oldID.replace("template", token);

        inputLabel.attr("for", newID);
        inputField.attr("id", newID);
    });
}

/**
 * This function updates the data attribute
 * "companionsslotsleft" in the companions list.
 * If it is 0 all saving or updating buttons
 * are disabled and if not they are enabled
 * again.
 *
 * @param companionsslotsleft The number of companion slots that are left for an event
 */
function updateMaxCompanionsState(companionsslotsleft) {
    $(".companions-list").attr("data-companionslotsleft", companionsslotsleft);
    if (companionsslotsleft == 0) {
        $("#clonecompanion-button")
            .addClass("disabled")
            .html(
                '<span class="fst-italic">Maximale Begleiter-Anzahl erreicht</span>'
            );
        $(".new-companion")
            .find(".save-companion-button")
            .prop("disabled", true);
    } else {
        $("#clonecompanion-button")
            .removeClass("disabled")
            .html("Begleitung hinzufügen");
        $(".new-companion")
            .find(".save-companion-button")
            .prop("disabled", false);
    }
}

/**
 * This function deletes companions. It checks if the companion is
 * already in data base or not. If not it removes just the DOM-Element.
 * If it is in the DB it send an ajax request with the companions ID
 * to the companion controller.
 */
function deleteCompanion() {
    /**
     *  Get the button that triggered the event
     */
    var companion = $(this).closest(".companion").first();

    if (companion.hasClass("new-companion")) {
        /**
         *  Remove the companion whose button triggered
         *  the event.
         */
        companion.fadeOut("slow", function () {
            $(this).remove();
        });
    } else {
        /**
         *  Collect the needed fields for delete request
         */
        var data = {
            CompanionID: companion.find("input[name=CompanionID]").val(),
        };

        /**
         *  Create the new request object and implement its
         *  response functions.
         */
        let request = $.ajax({
            url: "companion/deleteCompanion",
            dataType: "json",
            type: "POST",
            data: data,
            success: function (data, textStatus, jQxhr) {
                if (data.status == "success") {
                    /**
                     *  Remove the companion element related to the data object that
                     *  really has been removed on the server. Not that one that has
                     *  been clicked.
                     */
                    $("#companion-" + data.object.FrontendID).fadeOut(
                        "slow",
                        function () {
                            $(this).remove();
                        }
                    );

                    updateMaxCompanionsState(data.object.CompanionSlotsLeft);

                    // TODO: Show statusmessage in companions list
                } else if (data.status == "error") {
                    // TODO: Show statusmessage in companions list
                }
            },
            error: function (jqXhr, textStatus, errorThrown) {
                console.log(errorThrown);
            },
            always: function () {
                setCompanionToSavedState(companion);
            },
        });
    }
}

/**
 * This function takes the form that triggers the submission. This means
 * the form where "Speichern" has been clicked. All values form fields
 * are collected sent via POST request to the controller. All responses
 * are caught here and are going to be processed.
 *
 * @param form The form that triggers the submission
 */
function saveCompanion(form) {
    /**
     * Collect the request data
     */
    var url = form.attr("action");
    var data = {
        CompanionID: form.find("input[name=CompanionID]").val(),
        RegistrationID: form.find("input[name=RegistrationID]").val(),
        Salutation: form.find("select[name=Salutation]").val(),
        Degree: form.find("select[name=Degree]").val(),
        FirstName: form.find("input[name=FirstName]").val(),
        Surname: form.find("input[name=Surname]").val(),
        Email: form.find("input[name=Email]").val(),
        Mobile: form.find("input[name=Mobile]").val(),
        Phone: form.find("input[name=Phone]").val(),
        Address: form.find("textarea[name=Address]").val(),
        Company: form.find("textarea[name=Company]").val(),
        Allowed: form.find("input[name=Allowed]").prop("checked"),
    };

    /**
     *  If an old request is still active
     *  quit that one first before going on.
     */
    if (request) {
        request.abort();
    }

    /**
     *  Get the surrounding companion element and set
     *  it to the "saving state".
     */
    var companion = form.closest(".companion").first();
    setCompanionToSavingState(companion);

    /**
     *  Create the new request object and implement its
     *  response functions.
     */
    let request = $.ajax({
        url: "companion/saveCompanion",
        dataType: "json",
        type: "POST",
        data: data,
        success: function (data, textStatus, jQxhr) {
            if (data.status == "success") {
                updateCompanion(
                    "#companion-" + data.object.FrontendID,
                    data.object,
                    data.action
                );
                setCompanionToSavedState(companion);
                setStatusNotes(companion, "success", "");
                updateMaxCompanionsState(data.object.CompanionSlotsLeft);
            } else if (data.status == "danger") {
                setCompanionToUnsavedState(companion);
                setStatusNotes(companion, "danger", data.message);
            }

            setValidationNotes(companion, data.errors);
        },
        error: function (jqXhr, textStatus, errorThrown) {
            setCompanionToUnsavedState(companion);
            setStatusNotes(
                companion,
                "danger",
                "Der Server hat einen Fehler gemeldet! Bitte versuchen Sie es später erneut."
            );
        },
        always: function () {
            setCompanionToSavedState(companion);
            setStatusNotes(companion, "success", "");
        },
    });
}

/**
 * This function sets validation notes, if any
 * error occurs during companion creation or
 * updating
 *
 * @param companion A companion element
 * @param errors The errors that occurs while validation
 */

function setValidationNotes(companion, errors) {
    companion.find(".invalid-feedback").remove();
    companion.find(".valid-feedback").remove();
    companion.find(".is-invalid").removeClass("is-invalid");
    companion.find(".is-valid").removeClass("is-valid");

    if (typeof errors != "undefined") {
        $.each(errors, function (key, value) {
            var field = companion.find('[name="' + key + '"]');
            field.addClass("is-invalid");
            $.each(value, function (validation, message) {
                field
                    .parent()
                    .append(
                        '<div class="invalid-feedback">' + message + "</div>"
                    );
            });
        });
        companion.find(".save-companion-button").prop("disabled", true);
    }
}

/**
 * This function writes an notification
 * before the save-companion button
 *
 * @param companion A companion element
 * @param status The bootstrap status class
 * @param message The message that is written
 */

function setStatusNotes(companion, status, message) {
    companion.find(".alert").remove();
    if (message != "") {
        var saveCompanionButton = companion.find(".save-companion-button");
        $(
            '<div class="alert alert-' +
                status +
                '"><p class="mb-0">' +
                message +
                "</p></div>"
        ).insertBefore(saveCompanionButton);
    }
}

/**
 * This function updates the companion object and writes
 * the resent data from the ajax request before. It gets
 * the id of the companion to identify the object that has
 * to be updated, the updated data in a JSON-Object and
 * an action variable that says what tyoe ob action has been
 * done.
 *
 * @param id The object that has to updated
 * @param object The JSON-Object in a specific format
 * @param action The action that has been done like create, save or delete
 */
function updateCompanion(id, object, action) {
    var companion = $(id);
    reidentCompanion(companion, object.CompanionID);
    companion
        .find(".companion-preview-title")
        .html(
            object.Salutation +
                " " +
                object.Degree +
                " " +
                object.FirstName +
                " " +
                object.Surname
        );

    companion.find('select[name="Salutation"] option').removeAttr("selected");
    companion
        .find(
            'select[name="Salutation"] option[value="' +
                object.Salutation +
                '"]'
        )
        .attr("selected", true);
    companion.find('select[name="Degree"] option').removeAttr("selected");
    companion
        .find('select[name="Degree"] option[value="' + object.Degree + '"]')
        .attr("selected", true);
    companion.find('input[name="FirstName"]').val(object.FirstName);
    companion.find('input[name="Surname"]').val(object.Surname);
    companion.find('input[name="Email"]').val(object.Email);
    companion.find('input[name="Address"]').val(object.Address);
    companion.find('input[name="Company"]').val(object.Company);
    companion.find('input[name="Mobile"]').val(object.Mobile);
    companion.find('input[name="Phone"]').val(object.Phone);

    if (action == "create") {
        companion.removeClass("new-companion");
    }
}

/**
 *  This function disables the form submit button
 *  of any form if it has a class "one-clicker"
 *
 *  @param e The event of the triggering object
 */
function disableButton(e) {
    var oneClickerButton = $(this).find(".one-clicker");
    oneClickerButton.attr("disabled", true);
    oneClickerButton.val("Bitte warten");
    return true;
}

/**
 *  This function sets the Save-Button of a companion to enabled
 *  and changes the preview text so that it is clear that this
 *  companion is unsaved.
 *
 *  @param companion The companion object that has to be changed
 */
function setCompanionToUnsavedState(companion) {
    // Add unsaved class to the companion
    companion.addClass("unsaved");

    // Set the Save-Button enabled
    companion.find(".save-companion-button").prop("disabled", false);
    companion.find(".save-companion-button").html("Speichern");

    // Change preview title so that unsaved changes are recognizable
    companion.find(".companion-save-state").html("&nbsp;(ungespeichert)");
}

/**
 *  This function sets the Save-Button of a companion to disabled
 *  and changes the preview text so that it is clear that this
 *  companion is saved now.
 *
 *  @param companion The companion object that has to be changed
 */
function setCompanionToSavedState(companion) {
    // Add unsaved class to the companion
    companion.removeClass("unsaved saving");

    // Set the Save-Button enabled
    companion.find(".save-companion-button").prop("disabled", true);
    companion
        .find(".save-companion-button")
        .html('<span class="fas fa-check-circle"></span>');

    // Change preview title so that unsaved changes are recognizable
    companion.find(".companion-save-state").html("");
}

/**
 *  This function sets the Save-Button of a companion to disabled
 *  and changes the preview text so that it is clear that this
 *  companion is currently saving
 *
 *  @param companion The companion object that has to be changed
 */
function setCompanionToSavingState(companion) {
    // Add unsaved class to the companion
    companion.addClass("saving");

    // Get all input fields
    var $inputs = companion.find("input, select, button, textarea");

    // Set the Save-Button enabled
    companion.find(".save-companion-button").prop("disabled", true);
    companion
        .find(".save-companion-button")
        .html(
            "Speichern" + '&nbsp;<span class="fas fa-spinner fa-spin"></span>'
        );

    // Change preview title so that unsaved changes are recognizable
    companion.find(".companion-save-state").html("&nbsp;(wird gespeichert)");
}

/**
 *  This function binds all events inside a companion
 *  onto a companion and its elements
 *
 *  @param companion The companion object that has to be changed
 */
function bindCompanionEvents(companion) {
    /**
     *  Bind the keydown-event
     */
    companion
        .find('input[type="text"], input[type="email"], textarea')
        .on("keydown", function () {
            var companion = $(this).closest(".companion").first();
            setCompanionToUnsavedState(companion);
        });

    companion.find('select, input[type="checkbox"]').on("change", function () {
        var companion = $(this).closest(".companion").first();
        setCompanionToUnsavedState(companion);
    });

    companion.find(".save-companion-form").on("submit", function (e) {
        e.preventDefault();
        saveCompanion($(this));
        return false;
    });

    companion.find(".delete-companion-button").on("click", deleteCompanion);
}

/**
 *  Get all pre-DOM-ready found companions and
 *  bin all needed event handlers to each companion
 */
var allCompanions = $(".companion");
$.each(allCompanions, function (key, value) {
    bindCompanionEvents($(this));
});

/**
 *  If the clone companion button is clicked fire the
 *  cloneCompanion function
 */
$("#clonecompanion-button").on("click", cloneCompanion);

/**
 *  If any form has been submitted fire the disableButton
 *  function
 */
$("form").on("submit", disableButton);

/**
 *  If any checkbox is changed write or delete the checked
 *  property for a cleaner working
 */
$('input[type="checkbox"]').on("change", function () {
    if ($(this).prop("checked") == false) {
        $(this).removeAttr("checked");
    } else {
        $(this).attr("checked", "checked");
    }
});

/**
 *  If the comapnions modal is going to be hidden
 *  do some other stuff
 */
$("#companions-modal").on("hide.bs.modal", function () {
    $(".save-companion-button").html("Speichern");

    /**
     *  The the number of companions that are
     *  really saved in the DB and update the
     *  companions counter in the current user
     *  registration
     */
    var companionsCount = $(this).find(
        '.companions-list .companion:not(".new-companion")'
    ).length;
    $(".companions-count-value").html(companionsCount - 1);
});

/**
 *  If the comapnions modal is going to be shown
 *  do some stuff
 */
$("#companions-modal").on("show.bs.modal", function () {
    updateMaxCompanionsState($(".companions-list").data("companionslotsleft"));
});
